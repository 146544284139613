const articles = [
  {
    title: [
      {
        copy: "Maximise Your Media Coverage",
        size: "35",
      },
      {
        copy: "of Interior Trends for 2023",
        size: "35",
      }
    ],
    subTitle: 'by Rachael Ashmore, Q Communications Director',
    introtext:
      'Staying ahead of the latest décor trends is a must for home interest brands looking to place their products in the ‘go to’ interior design pages, where a journalist recommendation can quickly result in a flurry of sales and sell out lines.',
    date: "2023-02-7",
    image: require("@/assets/blogs/interior.jpg"),
    fullArticle:
      `<p>
      Staying ahead of the latest décor trends is a must for home interest brands looking to place their products in the ‘go to’ interior design pages, where a journalist recommendation can quickly result in a flurry of sales and sell out lines.
      With that in mind, as a home interest PR agency with extensive experience, at Q Comms we’ve done our research. We’ve scoured the home interest magazines to identify this year’s key interior trends that can inform your home interest PR strategy.
      Read on, for our roundup of what’s hot (and what’s not so) in interiors this year, according to leading journalists in the industry. Along with some tips from our home interest PR experts on how to achieve the best coverage using the biggest 2023 interior trends.
      </p>
      <h2>What are the big home décor trends for 2023?</h2>
      
      <div class="gallery-container">
        <div class="gallery">
          <img src="/interior/1.jpg" alt="interior">
        </div>
      </div>

      <div class="gallery-container">
        <div class="gallery">
          <img src="/interior/2.jpg" alt="interior">
        </div>
      </div>

      <div class="gallery-container">
        <div class="gallery">
          <img src="/interior/3.jpg" alt="interior">
        </div>
      </div>

      <div class="gallery-container">
        <div class="gallery">
          <img src="/interior/4.jpg" alt="interior">
        </div>
      </div>

      <div class="gallery-container">
        <div class="gallery">
          <img src="/interior/5.jpg" alt="interior">
        </div>
      </div>

      <div class="gallery-container">
        <div class="gallery">
          <img src="/interior/6.jpg" alt="interior">
        </div>
      </div>

      <div class="gallery-container">
        <div class="gallery">
          <img src="/interior/7.jpg" alt="interior">
        </div>
      </div>

      <div class="gallery-container">
        <div class="gallery">
          <img src="/interior/8.jpg" alt="interior">
        </div>
      </div>

      <p><strong>Cosiness and comfort</strong> – Ideal Home explains that soft layered textures – boucle, wool and furs - will continue to make our homes a place of sanctuary in 2023. Sofas are upgraded for comfort with a multitude of fluffy pillows and soft throws to make the living room a warm and inviting. Soft to touch rugs, furnishings and beanbags will add style and comfort. If this sounds like your product range, it’s time to shout about it, with the help of your home interest PR agency.</p>
      <p><strong>Making waves</strong> – coastal is a perennial trend for summer, but as well as cool oceanic inspired colourways, the wave motif is a strong influence throughout 2023. Find it not just on wallpaper and soft furnishings but in more unusual places like lamp stands, chair legs and hardware.</p>
      <p><strong>Bathrooms with personality</strong> – beige tiles be gone; this year bathrooms are all about colour and expression. Coloured sinks, matt black or green taps, bold patterns and wallpaper (yes in the bathroom). This look is best curated with bright and bold colourways, but neutrals and a mix of strong motifs work nonetheless – it’s all about expression! Don’t forget accessories can be a quick easy way to add personality too.</p>
      <h2>What are the colour trends for 2023?</h2>
      <p><strong>Viva Magenta</strong> – Pantone is the leader in colour foresight and this year has picked a strong crimson/pink shade to signify ‘power, exuberance and optimism.’
      Vogue states that <strong>earth tones</strong>, especially browns, pinks and warm neutrals, will still reign in popularity as colour trends for 2023 thanks to ‘their calming effects and associations with nature.’
      While the Good Housekeeping tip for the colour of the year is <strong>ultramarine blue</strong>, which it describes as ‘a bright, super saturated, and luscious colour.’
      </p>
      <h2>Which home décor trends are out?</h2>
      <p>A new year always marks change, so it’s out with the old and in with the new, here are some trends we’ll be waving goodbye to in 2023 according to House & Garden.
      <strong>Ultra-modern, minimalist kitchens</strong> with not a thing out of place aren’t just impractical, but out of favour in 2023. For kitchens think ‘warm, homey, joyful, and welcoming’.
      <strong>Colour-coordinated decor</strong> – we’re urged to open our minds to the possibility of mismatched arrangements in the home. If you have an eclectic product range, then this is the trend to jump on.

      Screens are a central part of most people’s daily lives, but too much so, according to this publication which predicts that <strong>TV-centred living rooms</strong> will be phased out in 2023. Even if your living space is small, find a small way to make it more sociable and interactive.
      </p>
      <h2>Tips to maximise your coverage of 2023 interior trends</h2>
      <p>With all that in mind, how can your home interest PR agency or home interiors social media agency help you jump on board with home interest trends to maximise your PR and social media strategy. Here are some tips to get your product pictures out of the journalists’ inboxes and onto the pages of your favourite magazines.</p>
      <ul>
        <li><strong>Photography is key</strong> – interiors publications are image-led. The stronger your pictures the more likely it is they’ll be selected. You’ll need a good selection of photography, both lifestyle and cut outs, with styling to match the seasons and trends for the coming year.
        New photography gives you an advantage too, it’s worth the annual investment rather than wasting time trying to peddle the same tired images with a different spin.</li>
        <li>Bring your vision alive, with a <strong>mood board</strong> of images, colour swatches and similar products to demonstrate how beautiful it could look on the page. Use your design team (and your home interest PR agency) for creative direction</li>
        <li>Include a <strong>snappy email title</strong>. One that does what it says on the tin and will cut through the noise in a journalist’s busy inbox.</li>
        <li><strong>Give them what they need</strong> – an email with strong images, some supporting copy (but not too much), links to buy products with prices and clear labelling, plus a Dropbox link to download high-res images (at least 300 dpi). Don’t fill your email with huge image files – it will never be delivered.</li>
      </ul>
      <br/>
      <h2>Where do interior design trends come from?</h2>
      <p>As a closing note its worth exploring where interior design trends come from. And the answer? Well, that’s a chicken and egg situation.

      Some trends will originate from designers and experts who will predict/create trends and design product lines accordingly. Some are driven by the consumer who will create demand for certain products, making them a must buy.</p>
      <p>The final trend source is from brands that spot a popular line with their customers, share this via their owned platforms (social media, website) with the help of your home interiors social media agency, and earned platforms (magazines and newspapers) via their home interiors PR company, thus amplifying the trends even more.

      Creating and selecting products according to what’s popular is something your brand is undoubtedly already doing, yet it’s often the bigger brands and household names that set the bar in terms of shouting about it.</p>
      <p>As a home interest brand, you need to become adept at this skill. Become the trendsetter and watch your brand awareness skyrocket.

      Large or small, here at Q Comms, as an experienced home interiors PR company, we’d love to help you share your brand’s products and trends with the world, so why get in touch to see how we can support you.</p>
      `,
    
  },
  {
    title: [
      {
        copy: "WHY THE CULINARY FOOD ",
        size: "35",
      },
      {
        copy: "FESTIVAL BY NORTHCOTE HAS ",
        size: "35",
      },
      {
        copy: "BECOME EVERYONE’S OBSESSION",
        size: "35",
      }
    ],
    subTitle: 'by Kate Burke, Q Communications Managing Partner',
    introtext:
      'You’d be hard pushed to find any foodie, or person in the north west of England for that matter, that hadn’t heard of our hotel and restaurant client Northcote’s Obsession festival. Since its inception in 2001, it’s fast become known as one of the most prestigious and internationally recognised gourmet festivals in Europe, famous for its flair, exceptional gastronomy and outstanding hospitality.',
    date: "2023-02-7",
    image: require("@/assets/blogs/northcote.jpeg"),
    fullArticle:
      `<p>You’d be hard pushed to find any foodie, or person in the north west of England for that matter, that hadn’t heard of our hotel and restaurant client Northcote’s Obsession festival. Since its inception in 2001, it’s fast become known as one of the most prestigious and internationally recognised gourmet festivals in Europe, famous for its flair, exceptional gastronomy and outstanding hospitality.
      </p>
      
      <p>And why you may ask?</p>
      <p>Well, there are many reasons (lots!), but I’ll list some of the key ones here:</p>
      <h2>The setting</h2>
      <p>Northcote is located in the Lancashire’s Ribble Valley, surrounded by miles of unspoiled and glorious countryside. It’s a treasured boutique hotel with a Michelin-starred restaurant, headed up by Lisa Goodwin-Allen. Northcote is perfectly situated to allow guests to enjoy the great outdoors or explore the quaint market towns and picturesque historic villages locally.</p>
      <h2>It’s record breaking and award winning</h2>
      <p>Last year, after the hospitality industry returned following the pandemic, the festival broke all records, raising a staggering amount of money for Hospitality Action and was awarded an ‘Outstanding Contribution Award’ at the AA Hospitality Awards.</p>
      <h2>The class of chefs is phenomenal</h2>
      <p>This year twenty world class chefs with 16 Michelin stars between them made the trip to luxury Lancashire hotel and Michelin star restaurant Northcote, which is headed up by executive chef Lisa Goodwin-Allen. The festival took place over 17 nights and saw a number of exciting and emerging chefs, as well as some of the world’s most established, cook their unique Obsession menus. Chefs this year included Brad Carter, <a href="https://cartersofmoseley.co.uk/">Carters of Mosley</a>
      , Tommy Banks, <a href="https://www.blackswanoldstead.co.uk/">The Black Swan</a> and Tom Sellars, <a href="https://restaurantstory.co.uk/">Restaurant Story</a>.</p>
      <p>Past chefs have included James Martin, Tom Kerridge, Mark Birchall, Simon Rogan, Monica Galetti and Angela Hartnett. </p>
      
      <div class="gallery-container">
        <div class="gallery">
          <img src="/chef/lisa-goodwin-allen.jpg" alt="Lisa Goodwin Allen">
          <div class="desc">Lisa Goodwin Allen</div>
        </div>

        <div class="gallery">
          <img src="/chef/tommy-banks.jpeg" alt="Tommy Banks">
          <div class="desc">Tommy Banks</div>
        </div>

        <div class="gallery">
          <a target="_blank" href="img_lights.jpg">
            <img src="/chef/tom-sellars.jpeg" alt="Tom Sellars">
          </a>
          <div class="desc">Tom Sellars</div>
        </div>

        <div class="gallery">
          <img src="/chef/monica-galetti.jpeg" alt="Monica Galetti">

          <div class="desc">Monica Galetti</div>
        </div>
      </div>

      <h2>The people</h2>
      <p>We have had the honour of working with Northcote and doing the publicity and media relations for the last two Obsession festivals, supporting them with the PR, communications and social media content surrounding the event. Nothing beats the team for passion and enthusiasm. Managing Director Craig Bancroft (or Mr B as he is affectionally known) can only be described as leading the team to provide world class hospitality. He’s a true host in every sense of the word. 

      And chef Lisa Goodwin-Allen and he team, wow, what they manage to do night after night is just outstanding. </p>
      <h2>The history</h2>
      <p>Obsession launched 2001 as a celebration of great food, wine and a meeting of friends, old and new. From that first event, it has evolved but the ethos is still very much the same; to offer guests a completely memorable culinary experience, created by some of the world’s most innovative chefs. </p>
      <h2>The marketing</h2>
      <p>The in-house marketing function at Northcote is superb. Each year, they engage a brilliant line up on sponsors and create a huge amount of buzz and anticipation on social media, supported by our team at Q Communications in the UK. As a hospitality, travel and F&B PR agency it’s our job to engage the media and ensure lots of column inches are written on the festival to ensure the event is a sell out within days of the tickets being released.</p>
      <h2>A final word</h2>

      <div class="gallery-container">
        <div class="gallery">
          <img src="/chef/chetan-sharma.jpeg" alt="Chetan Sharma">
          <div class="desc">Chetan Sharma</div>
        </div>
      </div>

      <p>I had the chance to experience two nights at Northcote’s Obsession in January this year. We hosted media on Lisa and Niall Keating’s opening night, and then returned back to enjoy the incredible menu by Chetan Sharma from Bibi in London. My own words can’t do the experience justice, so I’ll leave you with some of the feedback from the media who attended:</p>
      <p>The Independent:</p>
      <p><em>"Great to see you both last night and thank you for allowing me the honour of being part of such a momentous event. I have to pinch myself at the scale of what Northcote pulls off every year for Obsession. It's really quite incredible. We met some amazing people and enjoyed some incredible food and wine."</em></p>
      <p><em>"It was pretty awe-inspiring to be surrounded by chefs who have six Michelin stars between them. Thanks again for the invite."</em></p>
      <p>CODE Hospitality/The Good Food Guide:</p>
      <p><em>"I just wanted to say a huge thank you for hosting us at Obsession on Friday. It was such an enormous treat and so good to see first-hand what Northcote is all about, especially the quality of the food and hospitality there."</em></p>
      
      `,
    
    },
    {
    title: [
      {
        copy: "WHY YOUR UK BRAND SHOULD",
        size: "35",
      },
      {
        copy: "BE USING BEAUTY INFLUENCERS",
        size: "35",
      }
    ],
    subTitle: 'by Emily Ward, Q Communications Junior Communications Manager',
    introtext:
      'Beauty is becoming one of the largest sectors in the influencer industry and will continue to grow in 2023.',
    date: "2023-02-7",
    image: require("@/assets/blogs/blog-33.jpeg"),
    fullArticle:
      `<p>Beauty is becoming one of the largest sectors in the influencer industry and will continue to grow in 2023. But what is the best way to measure the impact of an influencer and guarantee return for your company in the form of follower growth or converting to customers? In my opinion, creating desirable content for brands has now become an art, with influencers spending time capturing creative and viral material for brands to use which beats any high-end photoshoot. Collaborating with influencers allows customers to see true results of the product before they invest and if you’re anything like me, you need to see those results for yourself before you take the plunge to hit the check-out button.
      </p>
      
      <p>Use this blog as your personal handbook when finding the perfect beauty influencer for you. Lucky for you, Q Communications has done all the leg work for you and all you need to do is read our top tips or better still hire us as your influencer marketing agency to do all negotiations for you and your brand, utilising our extensive list of contacts across all industries!
      </p>
      
      <h2>Driving sales</h2>

      <p>Search for influencers through trending hashtags and find users who complement your brand. If you have a specific USP; for example, your products are vegan, or your packaging is recyclable, ensure you collaborate with an influencer who also shares the same passion. This way you can connect with their like-minded audience.
      </p>
      
      <p>An influencer should add value to your brand, projecting your brand ethos to their wider audience and organically encouraging their followers to interact and engage with your social platform.
      </p>
      
      <h2>The content</h2>
      
      <p>Most influencers have a running theme on their grid, check to see if your product fits with their style. This is a great way to guarantee a post from the influencer. You also want to make sure that the user is sharing high quality content and is actively working with other brands in the industry. You want your influencer to believe in your product, their posts should be authentic and have an element of transparency.
      </p>

      <h2>UK beauty influencers to keep your eyes on in 2023</h2>
      <p>Ella Gorton, also known as <a href="https://www.instagram.com/_myskinstory/">@_myskinstory</a>. As a young adult, acne took over Ella’s life leading to her mental health suffering. After years of battling with her skin, Ella began to share her skin journey on social media, creating a relatable and safe space platform for thousands of other individuals to visit for advice and support. Ella is now the owner of her own skin clinic and continues to share her story with her <b>31.1K</b> followers. Ella’s content is authentic and highly transparent which is why she is loved by so many of her followers.

      Otilly Grace, <a href="https://www.instagram.com/otillygrace/">@otillygrace</a> has a following of <b>100K</b> and when it comes to photographing beauty in its purest form, Otilly knows a thing or two. Her content is pure and relatable with many other influencers following and engaging with her content.
      
      Lizzy Turner, aka <a href="https://www.instagram.com/elizabethkayeturner/">@elizabethkayeturner</a>. A feed full of creative make up tutorials and videos, once you start watching Lizzy’s content you can’t stop. Her videos have 5M views plus due to Lizzy simply showing the best way to create an eye look with eyeliner and lashes.
      </p>

      <h2>How many followers should an influencer have to make an impact on your brand?</h2>
      <p>It is important to remember that followers do not always equal conversions. Ensure you run influencers through an analytical tool to determine accurate statistics of how their account performs. Find a tool that enables you to measure the number of fake followers a user has, anything over 25% is typically seen as fraudulent activity.
      </p>
      <p>Secondly, you want to check the engagement rate. As a rule of thumb, the more followers you have the lower your engagement rate will be. Try and meet in the and middle and aim for a 3% engagement rate.</p>
      
      <h2>Different kind of influencers</h2>
      <ul>
        <li>Mega-influencers with more than a million followers.</li>
        <li>Macro-influencers with 500K to 1 million followers.</li>
        <li>Mid-tier influencers with 50K to 500K followers.</li>
        <li>Micro-influencers with 10K to 50K followers.</li>
        <li>Nano-influencers with 1K to 10K followers.</li>
      </ul><br/>

      <h2>A final note</h2>

      <p>Collaborating with social influencers is now an effective tool to increase brand awareness, increase engagement and drive sales. Surveys show that when it comes to purchasing make-up items, more than 60% of consumers decide to buy after hearing the reviews from an influencer. Influencer marketing is THE key to success for beauty and cosmetic brands, get in touch with our team at Q Communications to find the perfect influencer for your brand.
      </p>
      `
      ,
    
    },
];

export { articles };

<template>
  <v-main>
    <v-header
      :headerImage="image"
      :displayOverlay="true"
      :overlayCopy="overlayCopy"
    />
    <container type="bg-dark" id="start">
      <v-row>
        <v-col
          v-for="(item, id) in article"
          class="col-12"
          :key="id"
        >
          <v-card
            tile
            dark
            elevation="0"
            class="bg-transparent white--text mb-3"
          >
            <router-link
              :to="{ name: 'article', params: { id: id, date: item.date } }"
              ><v-img :src="item.image" max-height="400" /></router-link>
            <v-card-text class="pb-0">{{
              item.date | moment("DD MMM YYYY")
            }}</v-card-text>
            <v-card-title class="font-weight-bold">
              <span v-for="(item, id) in item.title" :key="id"
                >{{ item.copy.toUpperCase() }}&nbsp;</span
              >
            </v-card-title>
            <div v-if="item.subTitle" style="font-size: 16px; color:#ffffffb3; padding: 0 16px;">{{ item.subTitle }}</div>
            <v-card-text v-if="id === 0" v-html="item.introtext" />
            <v-card-actions style="padding: 8px 16px">
              <v-btn
                outlined
                plain
                tile
                class="px-5"
                :to="{ name: 'article', params: { id: id, date: item.date } }"
                >Read More</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </container>
  </v-main>
</template>

<script>
import vHeader from "@/layout/includes/Header.vue";
import { articles } from "@/data/articles.js";

export default {
  name: "Blog",
  components: {
    vHeader,
  },
  data() {
    return {
      image: require("@/assets/backgrounds/digital-1.jpg"),
      overlayCopy: [
        {
          copy: "Blogs & Articles",
          size: "50",
        },
      ],
      article: articles,
    };
  },
};
</script>
